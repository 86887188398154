/* External Dependencies */
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { httpOptions } from './../header-options/header-option';
import { catchError, retry } from 'rxjs/operators';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';


/* Internal Dependencies */
import { CoreService, StorageService, ConfigService } from '../service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient,
    private core: CoreService,
    private storag: StorageService,
    private router: Router,
    private keycloakService: KeycloakService
  ) { }

  public check_token_valid() {
    // console.log("check_token_valid");
    this.checkTokenServ().subscribe(
      (e: any) => {
        // console.log("token_valid:",e.token_valid)

        this.keycloakService.isLoggedIn().then(
          
          (res_login) => {
            // console.log("login", res_login);
            // console.log("token_valid", e.token_valid);
            if (res_login == false) {
              if (e.token_valid == false) {
                // console.log("ไม่ได้ login และ token ติ๊หมดอายุหรือไม่ได้ใช้")
                // this.deauthen();
                localStorage.removeItem('pswd');
                localStorage.removeItem('usnm');
                localStorage.removeItem('usid');
                localStorage.removeItem('usty');
                localStorage.removeItem('ustk');
              } else {
                // console.log("ไม่ได้ login และ token ติ๊ยังมีอายุอยู่")
                this.router.navigate(['/owner']);
              }
            } else {
              if (e.token_valid == false) {
                // console.log("isTokenExpired:",this.keycloakService.isTokenExpired())
                if (this.keycloakService.isTokenExpired() == false) {
                  this.initializeUserOptions();
                } else {
                  localStorage.removeItem('pswd');
                  localStorage.removeItem('usnm');
                  localStorage.removeItem('usid');
                  localStorage.removeItem('usty');
                }
                // console.log("login และ token ติ๊หมดอายุหรือไม่ได้ใช้")
              } else {
                // console.log("isTokenExpired:",this.keycloakService.isTokenExpired(5))
                // console.log("login และ token ติ๊ยังมีอายุ")
              }
            }

          }
        )
        // if(e.token_valid !== undefined && e.token_valid == false){
        // this.deauthen();
        // }else{
        // }
      }
    );
  }

  public checkTokenServ() {
    let url = this.core.getApiUrl(`/users/profile`);
    return this.http.get(url);
  }

  public check_role(role) {
    let role_check: any;
    if (role == "admin_d" || role == "admin_s") {
      role_check = 'admin';
    } else if (role == "owner") {
      role_check = 'owner';
    } else {
      role_check = false;
    }
    return role_check;
  }

  public deauthen() {
    this.storag.clears();
    let redirect_url: string = window.location.origin + "#/home";
    this.keycloakService.logout(redirect_url);
  }

  public clearStorage() {
    this.storag.clears();
  }



  /**
   * register server local 
   */
  private loginLocalServ(encrypt) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: encrypt })
    };
    let url = this.core.getApiUrl(`/security/authen`);
    return this.http.get(url, httpOptions);
  }

  public initializeUserOptions(): void {
    this.keycloakService.getToken().then((key) => {
      this.register_auth(this.keycloakService.getUsername(), key);
    });
  }

  public register_auth(username, passpharse) {
    this.loginLocalServ(passpharse).subscribe(
      (res: any) => { this.authorize(username, 'remember', res.uid, res.gtp, res.token); },
      (err) => { console.log("err:", err); }
    );
  }

  private authorize(username, remember, id, type, token) {
    this.storag.setSession(username, id, type, token);
    this.storag.setLocal();
  }

}
