import { KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
      keycloak.init({
        config: {
          url: 'https://env.cusmart.chula.ac.th:3000/auth',
          realm: 'aerobase',
          clientId: 'myapp',
        },
        initOptions: {
          onLoad: 'check-sso',
        checkLoginIframe: false,
        flow: 'standard',
        silentCheckSsoRedirectUri:
          // window.location.origin + '/pm25/assets/silent-check-sso.html', // serve test
          window.location.origin + '/assets/silent-check-sso.html', // serve real
        },
        loadUserProfileAtStartUp: true,
        enableBearerInterceptor: false,
      });
  }
