import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Cesium.buildModuleUrl.setBaseUrl('assets/cesium/');
Cesium.Ion.defaultAccessToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNGNjMWFlZi0xMWU3LTQxNmUtOWJmMi04MjFkMDYwNzU3NzEiLCJpZCI6NzE2MjUsImlhdCI6MTYzNTMyNzE5MH0.xlmu-cI_zuCKl4PrHdx9Yk300BXRFWHXrgadHazGHgM";
//Cesium.Ion.defaultAccessToken="";

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
