import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate, UrlTree } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthService } from './auth.service'
import { CoreService, ConfigService } from '../service';

// import { AuthPermissionService } from '../service/auth-permission.service';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private http: HttpClient,
    private authService: AuthService,
    // private authPremissServ: AuthPermissionService,
    private core: CoreService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      const requiredRoles = route.data.roles;
      
      // console.log("requiredRoles[0]:",requiredRoles[0]);
      // console.log("this.authenticated:",this.authenticated);

      if(requiredRoles[0] === 'public'){
        if (!this.authenticated){
          resolve(true);
        }else{
          resolve(true);
        }
      }

      if(requiredRoles[0] === 'owner'){
        if (!this.authenticated) {
          // this.keycloakAngular.login();
          // resolve(false);
          resolve(true);
        } else {
          resolve(true);
        }
      }

      if(requiredRoles[0] === 'admin'){
        if (!this.authenticated) {
          this.keycloakAngular.login();
          resolve(false);
        } else {
          resolve(true);
        }
      }
    })
  }

}