import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { AuthLocalService } from '../auth_local/auth-local.service';
import { MessageDialogService } from '../service/message-dialog.service';


export interface authen {
  gid:number;
  token:string;
  uid:number;
  valid:boolean;
};

export interface key {
  bsid: string;
  pk: number[];
};

declare var RSAKey: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  constructor(
    public authLocalServ: AuthLocalService,
    private msgDialog: MessageDialogService,
    private keycloakAngular:KeycloakService,
    public router: Router, 
  ) { }


  msg:string;
  invalid:boolean = false;
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    remember: new FormControl(false)
  })

  ngOnInit(): void {
    this.aeroBaseRedirect();
  }

  loginLocal() {
    let username = this.loginForm.value.username;
    let password = this.loginForm.value.password;
    let remember = this.loginForm.value.remember;

    if(username === '' && password ===''){
      this.invalid = true;
      this.msg = this.msgDialog.msgInputText('Username or Password are not correct.');
    }else if(username === ''){
      this.invalid = true;
      this.msg = this.msgDialog.msgInputText('Username are not correct.');
    }else if(password === ''){
      this.invalid = true;
      this.msg = this.msgDialog.msgInputText('Password are not correct.');
    } else {
      this.invalid = false;
      this.setKey(username,password,remember)
    }
  }

  rsa = new RSAKey();
  key:any = {};
  passpharse;
  userNotFound:boolean = false;
  private setKey(username:string,password:string,remember:boolean){
    this.authLocalServ.rsaGetServ().subscribe((key) => {
      this.rsa.setPublic(key.pk[0],key.pk[1]);
      let passpharse = this.rsa.encrypt(JSON.stringify({user: username, pass: password, remember: remember, bsid: key.bsid}));
      this.authen(username,password,remember,passpharse)
    });
  }

  private authen(username:string,password:string,remember:boolean,passpharse) {
    this.authLocalServ.loginLocalServ(passpharse).subscribe(
      (authen)=>{
        if(authen.valid){ this.authLocalServ.authorize(username,remember,authen.uid,authen.gid,authen.token); }
      },
      (err) => {
        this.invalid = true;
        this.msg = this.msgDialog.msgInputText('username or password incorrect');
      }
      );
  }

 
  /**
  * set login aerobase guard
  */

  public aeroBaseLogin(){
    localStorage.setItem('guard_type', 'guard-aerobase'); //set guard aerobase
    this.keycloakAngular.login();
  }
  public hide_login = false
  public aeroBaseRedirect(){
    let redirect_url = localStorage.getItem("redirect_url");
    this.keycloakAngular.isLoggedIn().then(
      (res) => {
        if(res){
          this.hide_login = true;
          if(redirect_url == "undefined"){
            this.router.navigate(['/home']);
          }else{
            this.router.navigate(['/home/'+redirect_url]);
          }
        }

      }
    );
  }

}
