import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from './auth_keycloak/auth.service';
import { HttpHeaders,HttpClient } from '@angular/common/http';

import { CoreService, StorageService } from './service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private keycloakService: KeycloakService,
    private core: CoreService,
    private storag: StorageService, 
  ){ }

  ngOnInit(): void {
  }
 
}
